html{
    font-family: 'Open Sans', sans-serif;
    color: $fontColor;
}

body{
    font-size: 100%;
}

h1, h2, h3, h4, h5, h6{
    margin: 5px 0 10px;
}

h1{
    font-size: 2.4rem;
    color: $gray-700;
}

h2, h3, h4, h5, h6{
    font-weight: 600;
    color: $gray-700;
}

h2{
    font-size: 2rem;
}

h3{
    font-size: 1.35rem;
}

h4{
    font-size: 1.3rem;
}

h5{
    font-size: 1.3rem;
}

h6{
    font-size: 1.2rem;
}

p{
    font-size: 1rem;
    margin: 0 0 10px;
    line-height: 1.5;
}

div{
    font-size: 1rem;
    line-height: 1.5;
}

a{
    text-decoration: none;
    outline: 0;
    color: $linkColor;

    &:hover{
        color: $linkHoverColor;
    }
}

blockquote{
    margin: 5px 0 5px 30px;
}

pre, code{
    padding: 10px;
    margin: 10px 0;
    background: #f5f5f5;
    border: 1px solid #DDD;
    overflow: auto;
    font-size: 1rem;
}

ul, ol{
    margin: 0 0 10px 10px;
    padding: 0 0 0 25px;
    font-size: 1rem;
}

input{
    padding: 5px 7px;
    outline: 0;
    border: 1px solid $borderColor;
    border-radius: 3px;
    font-size: 1rem;
    font-family: inherit;
}

label{
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    font-family: inherit;
}

textarea{
    width: 100%;
    border: 1px solid $borderColor;
    outline: 0;
    border-radius: 3px;
    padding: 10px; 
    box-sizing: border-box;
    font-family: inherit;
}

button, .submit{
    background: #e0e0e0;
    padding: 5px 7px;
    border: 1px solid #cecece;
    border-radius: 3px;
    outline: 0;
    cursor: pointer;
    font-family: inherit;
}

img{
    max-width: 100%;
    height: auto;
}

.super{
    font-size: 0.5rem;
    vertical-align: super;
}