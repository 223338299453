// Import Font
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap');

// Added Normalize
@import "extra/style-normalize.css";

// Color variables
@import "prefab/colors";

/* Global Variables */
@import "prefab/global-variables";

/* Border Box Box Sizing */
html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

body{
    margin: 0;
    padding: 0;
    font-size: 13px;
    background: $bodyBG;

    // #root{
    //     overflow-x: hidden
    // }
}

/* Typography: Handles the way fonts look. Also handles bottom margin of text. */
@import "import/style-typography";

/* Header CSS. Includes Search bar CSS as well. */
@import "import/style-header";

/* Content area CSS (Post and Page). Includes Sidebar and Homepage/Archive/Search/Comment CSS as well. */
@import "import/style-content";

/* Footer CSS */
@import "import/style-footer";

/* Mobile CSS */
@import "import/style-mobile";