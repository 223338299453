$bodyBG: #f8f8f8;
$fontColor: $gray-500;
$linkColor: $lightBlue-600;
$linkHoverColor: $lightBlue-500;

$alertColor: #eee45b;
$successColor: rgb(72, 196, 55);
$failColor: rgb(211, 54, 54);

$borderColor: #DDD;
 
$site-width: 900px;

$box-shadow: 0px 1px 1px 3px rgba(0, 0, 0, 0.12);
$box-shadow-inverted: 0px -1px 1px 3px rgba(0, 0, 0, 0.12);

$box-shadow-container: 0 1px 1px rgba(0,0,0,.1);
$box-shadow-container-hover: 0 8px 15px rgba(0,0,0,.2);