.desktop{
    display: block!important;
}

.mobile{
    display: none!important;
}

.lazy-image{
    background: $warmGray-50;
    position: relative;
    width: 100%;

    .image-loader{
        position: absolute;
        width: 50px;
        height: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:before{
            display: block;
            content: "";
            width: 50px;
            height: 50px;
            border: 4px solid $lightBlue-100;
            border-top: 4px solid $lightBlue-600;
            border-radius: 50px;
            animation: 1s linear infinite rotate360;
        }
    }

    img{
        opacity: 0;
        transition: 0.25s all;
        display: block;
        position: relative;
        z-index: 1;

        &.loaded{
            opacity: 1;
        }
    }
}

.loading-page{
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &::before{
        content: "";
        display: block;
        width: 150px;
        height: 150px;
        border: 5px solid $lightBlue-500;
        border-top: 5px solid #FFF;
        animation: 0.5s linear infinite rotate360;
        border-radius: 150px;
    }

    h1{
        font-weight: normal;
        margin-top: 40px;
        font-size: 1.5rem;
    }
}

.hero-part{
    // background: $lightBlue-600;
    position: relative;
    height: calc(100vh - 60px);
    margin-bottom: 40px;

    .lazy-image{
        background: linear-gradient(45deg, $lightBlue-600, $lightBlue-800);
        width: 100%;
        height: 100%;
        opacity: 0.8;

        img{
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .hero-text{
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $lightBlue-50;
        text-align: center;
        width: 100%;
        max-width: 600px;
        padding: 30px 0;
        text-shadow: 0px 2px 10px $gray-900;

        h1{
            font-size: 5rem;
            margin: 0 0 10px;
            line-height: 1;
            color: #FFF;
        }

        p{
            margin: 0;
        }
    }

    .scroll-down{
        position: absolute;
        bottom: 10px;
        width: 100%;
        left: 0;
        text-align: center;
        color: $lightBlue-50;
        font-weight: bold;
        font-size: 0.8rem;
        text-shadow: 0px 0px 10px #000;
        animation: 1s linear infinite bounce;

        i{
            display: block;
            font-size: 1.2rem;
        }
    }
}

@keyframes flyAround0 {
    0%{
        transform: translate(0, 0);
    }

    25%{
        transform: translate(0, -100px);
    }

    100%{
        transform: translate(0, 0);
    }
}

@keyframes flyAround1 {
    0%{
        transform: translate(0, 0);
    }

    25%{
        transform: translate(-300px, -300px);
    }

    100%{
        transform: translate(0, 0);
    }
}

@keyframes flyAround2 {
    0%{
        transform: translate(0, 0);
    }

    25%{
        transform: translate(300px, 300px);
    }

    100%{
        transform: translate(0, 0);
    }
}

@keyframes rotate {
    100%{
        transform: rotate(360deg);
    }
}

@keyframes earthMove {
    0%{
        transform: translate(0);
    }

    50%{
        transform: translate(-20vw, -10vw);
    }

    100%{
        transform: translate(0);
    }
}

.homepage-inner{
    background: linear-gradient(#00000C, #303344);
    padding: 65px 0 5px 0;
    position: relative;
    overflow: hidden;

    .hero-text{
        position: relative;
        z-index: 2;
    }

    .star{
        position: absolute;
        background: #FFF;
        border-radius: 5px;
        box-shadow: 0 0 2px 1px white;

        &-type0{
            width: 1px;
            height: 1px;
            animation: flyAround0 200s linear infinite;
        }

        &-type1{
            width: 2px;
            height: 2px;
            opacity: 0.8;
            animation: flyAround1 150s linear infinite;
        }

        &-type2{
            width: 3px;
            height: 3px;
            animation: flyAround2 200s linear infinite;
        }
    }

    .earth-orbit{
        position: absolute;
        z-index: 1;
        top: 8%;
        right: -10%;
        width: 40vw;
        // animation: 50s ease-in-out infinite earthMove
    }

    .moon-orbit{
        animation: 100s linear infinite rotate;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .planet{
        &.mars{
            position: absolute;
            z-index: 1;
            top: 90vh;
            left: -10vw;
            width: 30vw;
        }

        &.neptune{
            position: absolute;
            z-index: 1;
            top: 150vh;
            right: -20vw;
            width: 50vw;
        }

        &.moon{
            width: 20%;
        }
    }

    .stats{
        background: rgba(0, 0, 0, 0.137);
        padding: 25px 20px 5px;
        border-radius: 10px;
    }

    .project-text{
        background: rgba(0, 0, 0, 0.137);
        padding: 20px 20px 20px;
        border-radius: 10px;
        z-index: 4;
        position: relative;
    }

    .stats, .projects{
        color: #FFF;
        margin: 50px auto;
        z-index: 4;
        position: relative;

        h1{
            color: #FFF;
        }

        ul{
            li{
                color: #FFF;
            }
        }
    }

    .dig-dug{
        position: absolute;
        bottom: 0;
        left: 10px;
        width: 120px;
        display: flex;
        opacity: 0.2;

        img{
            width: 25%;
        }
    }
}

// .stats, .projects{
//     padding: 0 10px;
// }

.stats{
    opacity: 0;
    transform: translateY(-40px);
    transition: 1s all;
    max-width: 900px;
    margin: 0 auto;
    &.visible{
        opacity: 1;
        transform: translateY(0);

        .confidence-bar{
            .confidence{
                .inner-confidence{
                    &.width-{
                        &95{
                            width: 95%;
                        }
                        &90{
                            width: 90%;
                        }
                        &85{
                            width: 85%;
                        }
                        &80{
                            width: 80%;
                        }
                        &75{
                            width: 75%;
                        }
                        &70{
                            width: 70%;
                        }
                    }
                }
            }
        }
    }

    span{
        margin: 0;
        font-size: 0.8rem;
        color: $lightBlue-400;
    }

    h1{
        font-size: 1.5rem;
        margin: 0 0 10px;
    }

    p{
        margin: 0 0 10px;
        line-height: 1.68
    }

    ul{
        columns: 2;
        max-width: 400px;
        margin: 0 0 30px;
        font-size: 0.85rem;
        color: $lightBlue-400;
        list-style: none;
        padding: 0;

        li{
            color: $fontColor;
            margin: 0 0 5px;

            &:before{
                content: "▹";
                color: $lightBlue-400;
                margin-right: 4px;
            }
        }
    }

    .bar-holder{
        margin: 0 0 40px;
    }

    .confidence-bar{
        display: flex;
        align-items: center;
        margin: 0 0 10px;
        display: grid;
        grid-template-columns: 250px auto;

        .name{
            text-align: center;
            display: flex;
            justify-content: center;

            i{
                font-size: 1.5rem;
                margin-right: 5px;
            }
        }

        .confidence{
            height: 1.5rem;
            background: $gray-200;
            border-radius: 5px;

            .inner-confidence{
                height: 100%;
                background: $lightBlue-400;
                border-radius: 5px;
                transition: 1s all;
                transition-delay: 0.5s;
                width: 0%;
            }
        }
    }
}

.projects{
    opacity: 0;
    transform: translateY(-40px);
    transition: 1s all;
    max-width: 900px;
    margin: 40px auto;

    &.visible{
        opacity: 1;
        transform: translateY(0);
    }
    
    span{
        margin: 0;
        font-size: 0.8rem;
        color: $lightBlue-400;
    }

    h1{
        font-size: 1.5rem;
        margin: 0 0 10px;
    }

    p{
        margin: 0 0 10px;
        line-height: 1.68
    }

    .homepage-projects{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        margin-top: 30px;

        .single-post > a{
            text-align: left;
            align-items: start;
            border-radius: 10px;
            box-shadow: 0 2px 2px rgba(0,0,0,.3);
            transition: box-shadow 0.25s;
            background: #FFF;

            display: block;
            position: relative;
            overflow: hidden;

            &:before{
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.755));
                z-index: 3;
            }

            &:hover{
                color: $lightBlue-600;
                box-shadow: 0 8px 15px rgba(0,0,0,.2);

                h3{
                    color: $lightBlue-500;
                }

                .thumbnail{
                    img{
                        transform: scale(1.3);
                    }
                }
            }

            h3{
                transition: color 0.25s;
            }

            .thumbnail{
                width: 100%;
                height: 300px;
                overflow: hidden;
                border-radius: 5px;

                >div, img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    background: #000;
                }

                img{
                    transform: scale(1.2);
                }
            }

            .content{
                position: absolute;
                z-index: 4;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 0 10px 10px;
                
                .title{
                    h3{
                        font-size: 1.2rem;
                        color: #FFF;
                        margin: 0;
                    }
                }

                .description{
                    color: $fontColor;
    
                    p{
                        font-size: 0.8rem;
                        margin: 0 0 10px;
                        color: #FFF;
                    }
                }
            }

            .tags{
                display: flex;
                flex-wrap: wrap;

                .inner-tag{
                    .single-tag{
                        background: none;
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }

    .view-more{
        display: block;
        width: 200px;
        margin: 30px auto 30px;
        text-align: center;
        background: $lightBlue-500;
        color: $lightBlue-50;
        transition: 0.25s all;
        padding: 10px 0;
        border-radius: 5px;
        box-shadow: $box-shadow-container;

        &:hover{
            background: $lightBlue-400;
            box-shadow: $box-shadow-container-hover;
        }
    }
}

.portfolio-header{
    grid-area: a;
    text-align: center;
    background: linear-gradient(45deg, $lightBlue-400, $lightBlue-700);
    color: #FFF;
    border-bottom: 5px solid $lightBlue-600;
    padding: 110px 0 50px;
    width: 100%;
    box-shadow: $box-shadow;

    h1{
        font-weight: normal;
        color: #FFF;
    }

    p{
        font-size: 0.9rem;
    }
}

.portfolio-grid{
    display: grid;
    column-gap: 20px;
    padding: 0 0 20px;

    grid-template: 
        "a a" auto
        "b c" auto / 300px auto
    ;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.filter-options{
    display: block;
    position: sticky;
    top: 0;
    padding: 10px 0 0 10px;
    grid-area: b;
}

.all-posts{
    grid-area: c;
    padding: 20px 10px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 20px;
    row-gap: 20px;

    .no-results{
        width: 100%;
        grid-column: 1 / 4;
        min-height: calc(100vh - 300px);
    }

    .single-post{
        color: $gray-700;
        border-radius: 10px;
        backface-visibility: hidden;
        display: block;
        box-shadow: 0 1px 1px rgba(0,0,0,.1);
        transition: box-shadow 0.25s;
        background: #FFF;
        overflow: hidden;
        min-height: 100px;

        a{
            display: block;
            height: 100%;
        }

        &:hover{
            color: $lightBlue-600;
            box-shadow: 0 8px 15px rgba(0,0,0,.2);

            h3{
                color: $lightBlue-500;
            }

            .thumbnail{
                img{
                    transform: scale(1.3);
                }
            }
        }

        h3{
            font-weight: 600;
            font-size: 1.2rem;
            padding: 0 0 5px;
            margin: 0 0 5px;
            transition: color 0.25s;
            line-height: 1;
        }

        .description{
            p{
                font-size: 0.8rem;
                line-height: 1.6;
                color: $gray-400;
                margin: 0;
            }
        }

        .content{
            padding: 10px;
        }

        .thumbnail{
            width: 100%;
            height: 150px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            >div{
                width: 100%;
                height: 100%;

                img{
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    transform: scale(1.2);
                }
            }

            span{
                position: absolute;
                display: block;
                object-fit: cover;
                width: 100%;
                height: 100%;
                transition: 0.25s all;
                border-radius: 5px;
                overflow: hidden;

                img{
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .tags{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            margin-top: 10px;
        }
    }
}

.inner-tag{
    .single-tag{
        background: $gray-200;
        color: $gray-400;
        line-height: 1;
        border-radius: 20px;
        font-size: 0.8rem;
        margin: 0 5px 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;

        &:before{
            font-family: "Font Awesome 5 Brands"; 
            font-weight: 900;
            font-style: normal;
            text-rendering: auto;
            display: block;
            margin-right: 5px;
            font-variant: normal;
        }

        &.css{
            &:before{
                content: "\f13c";
            }
        }

        &.html{
            &:before{
                content: "\f13b";
            }
        }

        &.javascript, &.jquery, &.json-api, &.ajax{
            &:before{
                content: "\f3b8";
            }
        }

        &.php{
            &:before{
                content: "\f457"
            }
        }

        &.react, &.redux{
            &:before{
                content: "\f41b"
            }
        }

        &.vue, &.vuex{
            &:before{
                content: "\f41f";
            }
        }

        &.wordpress, &.woocommerce{
            &:before{
                content: "\f411"
            }
        }

        &.android{
            &:before{
                content: "\f17b"
            }
        }

        &.java{
            &:before{
                content: "\f4e4";
            }
        }

        &.unity{
            &:before{
                content: "\e049"
            }
        }

        &.python{
            &:before{
                content: "\f3e2"
            }
        }

        &.node-js{
            &:before{
                content: "\f3d3"
            }
        }

        &.c{
            &:before{
                content: "\f17a";
            }
        }
    }
}

.filter-options{
    h3{
        font-size: 1rem;
    }

    .filter-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 5px;

        .github{
            width: 100%;
            display: grid;
            grid-column: span 2;
            grid-template-columns: 50px auto;
            align-items: center;
            padding: 10px 20px;
            background: $gray-100;
            border: 1px solid $gray-200;
            color: $gray-400;
            border-radius: 5px;
            margin-top: 20px;

            .icon{
                i{
                    font-size: 40px;
                }
            }

            .text{
                font-size: 0.9rem;

                a{
                    color: $gray-400;
                    font-weight: bold;
                    transition: 0.25s all;

                    &:hover{
                        color: $gray-500;
                    }

                    i{
                        display: inline-block;
                        font-size: 0.8rem;
                        transform: translateY(-2px);
                    }
                }
            }
        }
    }

    .single-tag-filter{
        @extend .inner-tag;
        
        .single-tag{
            justify-content: flex-start;
            background: #FFF;
            transition: 0.25s all;
            cursor: pointer;
            margin: 0;
            // color: #FFF;
            box-shadow: $box-shadow-container;
            padding: 10px 15px;

            &.selected{
                color: #FFF;
                background: $lightBlue-600;
                padding-left: 25px;
    
                &:hover{
                    background: $lightBlue-600;
                    padding-left: 25px;
                }
            }

            &:hover{
                color: #FFF;
                background: $lightBlue-400;
                padding-left: 20px;
                box-shadow: $box-shadow-container-hover;
            }
        }
    }
    
    .reset-filter{
        background: $lightBlue-500;
        text-align: center;
        grid-column: span 2;
        border-radius: 5px;
        padding: 10px 5px;
        font-size: 0.9rem;
        margin-top: 15px;
        color: #FFF;
        cursor: pointer;
        transition: 0.25s all;
        box-shadow: $box-shadow-container;

        &:hover{
            background: $gray-200;
            box-shadow: $box-shadow-container-hover;
            background: $red-500;

            i{
                backface-visibility: hidden;
                animation: 1s linear infinite rotate360negative;
                line-height: 1;
            }
        }
    }
}

.contact-header{
    @extend .portfolio-header;
}

.contact-form{
    max-width: 900px;
    margin: 0 auto;
    padding: 50px 10px 120px;

    p{
        margin: 15px 0 0;
        font-size: 0.8rem;
    }

    input{
        width: 50%;
    }

    button{
        width: 50%;
        margin-top: 30px;
        background: $lightBlue-500;
        border: 1px solid $lightBlue-500;
        color: $lightBlue-50;
        transition: 0.25s all;
        padding: 10px;
        box-shadow: $box-shadow-container;

        &:after{
            content: "";
            font-family: "Font Awesome 5 Free"; 
            font-weight: 900;
            font-style: normal;
            text-rendering: auto;
            margin-left: 5px;
            display: inline-block;
        }

        &.SUBMITTING{
            &:hover{
                transform: none;
                box-shadow: none;
                cursor: default;
            }

            &:after{
                content: "\f1ce";
                animation: 1s linear infinite rotate360;
            }
        }

        &.SUBMITTED{
            border: 1px solid $green-600;
            background: $green-500;

            &:hover{
                background: $green-500;
                transform: none;
                box-shadow: none;
                cursor: default;
            }

            &:after{
                content: "\f00c";
            }
        }

        &:hover{
            box-shadow: $box-shadow-container-hover;
            background: $lightBlue-400;
        }
    }
}

.single-page{
    .post-loading{
        .header-image{
            background: $warmGray-800;
        }

        .inner-grid{
            .sidebar-information{
                .breadcrumb{
                    margin: 20px 0 0;
                    span{
                        display: block;
                        margin: 0;
                    }
                }

                .tags{
                    margin-top: 10px;

                    >span{
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        justify-content: center;

                        span{
                            width: 25%;
                            margin: 0 3px 6px;
                        }
                    }
                }
            }

            .main-content{
                .header-single{
                    h1{
                        width: 100%;
                    }
                }

                .tab-selector{
                    a, span{
                        border: 0;
                        display: flex;

                        a, span{
                            width: 100px;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }

    .inner-grid{
        display: grid;
        padding: 0 10px;
        grid-template:
            "a a" auto
            "b c" auto / 300px auto
        ;
        max-width: 1100px;
        margin: 0 auto;
    }

    .header-image{
        grid-area: a;
        height: 300px;
        // margin-top: 60px;

        >div{
            height: 300px;
            overflow: hidden;
            width: 100%;
            display: block;
            background: $warmGray-800;
            // background: linear-gradient(45deg, $lightBlue-400,  $lightBlue-600);

            img{
                display: block;
                object-fit: cover;
                width: 100%;
                height: 100%;
                filter: blur(10px);
            }
        }
    }

    .sidebar-information{
        grid-area: b;
        padding: 10px;
        margin-top: -40px;
        position: relative;
        background: $bodyBG;
        border-radius: 10px 10px 0 0;
        z-index: 3;

        img{
            border-radius: 5px;
        }

        .inner-sidebar{
            position: sticky;
            top: 20px;
        }

        .breadcrumb{
            margin: 10px 0 3px;
            text-align: center;
            font-size: 0.8rem;

            a, span{
                margin: 0 5px;
            }
        }

        .tags{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 10px 0;
        }

        .contact-button{
            display: block;
            text-align: center;
            background: $lightBlue-600;
            color: #FFF;
            padding: 10px;
            border-radius: 5px;
            transition: 0.25s all;
            margin-top: 10px;
            box-shadow: $box-shadow-container;

            &:hover{
                background: $lightBlue-500;
                box-shadow: $box-shadow-container-hover;
            }
        }
    }

    .main-content{
        grid-area: c;
        padding: 0 10px;

        .header-single{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0 0;

            h1{
                color: $gray-600;
                margin: 0;
            }

            .visit-website{
                background: $lightBlue-600;
                color: #FFF;
                padding: 10px 30px;
                border-radius: 5px;
                transition: 0.25s all;
                box-shadow: $box-shadow-container;
                z-index: 3;

                &:hover{
                    background: $lightBlue-500;
                    box-shadow: $box-shadow-container-hover;
                }
            }
        }

        .tab-selector{
            border-bottom: 1px solid $gray-200;
            display: flex;
            position: sticky;
            top: 0;
            background: $bodyBG;
            z-index: 2;

            a, span{
                border-bottom: 4px solid transparent;
                cursor: pointer;
                transition: 0.25s all;
                display: block;
                padding: 10px 20px;
                font-size: 0.9rem;
                color: $gray-700;
                margin-bottom: -1px;

                &.active{
                    border-bottom: 4px solid $lightBlue-500;

                    &:hover{
                        border-bottom: 4px solid $lightBlue-500;
                    }
                }

                &:hover{
                    border-bottom: 4px solid $lightBlue-300;
                }
            }
        }

        .tab-content{
            padding: 0 0 20px;
            padding: 0 2px 20px;

            h3{
                margin: 20px 0 10px;
            }

            p{
                line-height: 1.8;
                margin: 0 0 20px;
            }

            hr{
                border: 0;
                height: 1px;
                width: 80%;
                margin: 30px auto;
                background: $warmGray-200;
            }

            .media{
                padding: 30px 0 0;
                min-height: 600px;
                
                >div{
                    display: flex!important;
                    text-align: center;
                    margin:  0 0 30px;
                    background: $gray-100;
                    border-radius: 5px;
                    overflow: hidden;
                    justify-content: center;
                }
            }
        }
    }
}

@keyframes rotate360{
    0%{
        transform: rotate(0deg)
    }
    100%{
        transform: rotate(360deg)
    }
}

@keyframes rotate360negative{
    0%{
        transform: rotate(360deg)
    }
    100%{
        transform: rotate(0deg)
    }
}

@keyframes bounce{
    0%{
        transform: translateY(0);
    }

    50%{
        transform: translateY(-5px);
    }

    100%{
        transform: translateY(0);
    }
}

.widget-area{
    padding: 10px;
}