//Uncomment if header is on top of splash
/* body{
    position: relative;
} */

.header{
    padding: 10px;
    background: $lightBlue-600;
    border-bottom: 5px solid $lightBlue-800;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: $box-shadow;
    position: relative;
    z-index: 5;
    transition: 0.25s all;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &.home{
        background: transparent;
        border-color: transparent;
        box-shadow: none;
    }

    .logo{
        font-weight: 400;
        color: $lightBlue-50;
        line-height: 1;
        font-size: 1.3rem;
        padding-left: 10px;

        a{
            color: $lightBlue-50;
        }

        .smaller{
            font-size: 0.7rem;
        }
    }

    .right-side{
        margin-left: auto;
    }

    //Uncomment if header needs to be on top of splash
    //@include floatingHeader
}

.nav-links{
    display: flex;
    justify-content: flex-end;

    a{
        color: $lightBlue-50;
        margin: 0 10px;
        padding: 5px 10px;
        border-radius: 5px;
        transition: 0.25s all;

        &:hover{
            background: $lightBlue-700;
        }
    }
}

.mobile-menu{
    .hamburger-menu{
        color: #FFF;
        cursor: pointer;
        font-size: 1.5rem;
        padding-right: 10px;
        width: 40px;
        text-align: center;
        display: none;
    }
    
    .links{
        position: absolute;
        left: 0;
        background: linear-gradient(45deg, $lightBlue-400, $lightBlue-600);
        width: 100%;
        top: 60px;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.212);

        .nav-links{
            flex-direction: column;
            align-items: center;
            padding: 10px 0;
        }
    }
}